<template>
    <div v-bind:style="styles">
      <div id="page-content" v-if="!viLoader">
        <div class="webNew-dispflex">
       
       <div class="backbox" style="margin-left: 3px;">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Leave Request
       </h4>
     </div>
        <div class="row mt-2 ml-2 mr-2">
          <div class="col-lg-12">
            <div class="widjet">
             
              <div class="p-3 widjetcontent">
                <div class="widjetcontent mt-1 tbllist">
                      <div>
                        <div class="crtfeeplancont">
                         
                          <div class="restable">
                            <div class="resrow resheader">
                                <!-- <div class="cell">Class </div>
                                <div class="cell">Class Room </div> -->
                                <div class="cell">From </div>
                                <div class="cell">To</div>
                                <div class="cell">No Of Days</div>
                                <div class="cell">Applied Date</div>
                                <div class="cell">Status</div>
                            </div>
                            <div
                              class="resrow"
                              v-for="(item, index) in leaveRequestList"
                              :key="index"
                            >
                            <!-- <div class="cell">{{ item.class ? item.class.className : '' }}</div>
                            <div class="cell">{{ item.section ? item.section.name : '' }}</div> -->

                            <div class="cell">{{ item.from | dateFormat }}</div>
                            <div class="cell">{{ item.to | dateFormat }}</div>
                            <div class="cell">{{ noOfDaysLeave(item.noOfDates) }}</div>
                            
                            <div class="cell">{{ item.createdDate | dateFormat }}</div>
                            <div class="cell" :style="item.status == 'Pending Approval' ? 'color: darkorange;' : '' ">{{ item.status || '' }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <vi-spinner
                  v-if="viLoader"
                  text="Loading..."
                  textColor="vi-brand-color"
                  class="flex-fill h-100 vi-fs12"
                  style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
                />
      
    </div>
  </template>
  <script>
  import ViSpinner from "../Common/ViSpinner";
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import order from "../Common/order";
  import "vue-select/dist/vue-select.css";
  import Select from "vue-select";
  
  export default {
    name: "SUBSCRIPTION",
  
    data() {
      return {
        viLoader: false,
        selectedMonth: null,
        leaveRequestList: []
      };
    },
    created() {
      this.getStudentLeaveRequestList();
  
    },
    computed: {
      styles(){
         var brwHeight = window.innerHeight;
         return{
           height: (brwHeight - 90) + 'px'
         };
      },
     
    },
  
    methods: {
      previousRoute(){
        this.$router.push(`/parent/student/home/${this.$route.params.id}`);
    //    let data = localStorage.getItem('previousTab');
    //    localStorage.setItem("activeTab",data);
    //    localStorage.setItem("previousTab",this.$route.path);
    //  //  this.$router.go(-1)
    //     this.$router.push({ path: data });
      },
      noOfDaysLeave(data){
        let totalDays = data.map((o) => Number(o.noDays))
              .reduce((a, c) => {
                return a + c;
              });

              if(totalDays){
                return  totalDays

              }else{
                return  '-'

              }
      },
      async getStudentLeaveRequestList() {
        this.viLoader = true
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/home/getStudentLeaveReuestList/${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          this.leaveRequestList = result

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
      this.viLoader = false
    },
      redirectRoute(vipath) {
        if (this.$route.path !== vipath) this.$router.push({ path: vipath });
      }
    },
    components: {
      "v-select": Select,
      ViSpinner
    },
  };
  </script>
  <style scoped>
  ul,
  ul li,
  p,
  div,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  #invoice {
    margin: 0 auto;
    width: 999px;
  
    clear: both;
    position: relative;
    background: #fff;
  }
  .feedate {
    font-size: 0.78rem;
    text-align: right;
    margin: 0 auto;
    padding: 10px 0;
    width: 999px;
  }
  table,
  td,
  th {
    border: 1px solid black;
  }
  
  @media screen {
    #print {
      display: none;
    }
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    #print,
    #print * {
      visibility: visible;
    }
    #print {
      position: absolute;
      left: 0;
      top: 0;
    }
    .no-print,
    .no-print * {
      display: none !important;
    }
  }
  
  /* table{
           border-collapse:collapse;
           width:100%;
          clear:both;
        } */
  </style>